import Cookies from "js-cookie";
import UA from "../lib/UA";
import wait from "../lib/wait";
import { preloadImages } from "../lib/preloadImage";
import * as Config from "./Config";
const constants = require("../../../constants.json");

const DEFAULT_WIDTH_PC= 1280;
const DEFAULT_HEIGHT_PC = 720;
const DEFAULT_WIDTH_SP = 375;
const DEFAULT_HEIGHT_SP = 750;

const DEFAULT_ASPECT_PC = DEFAULT_HEIGHT_PC / DEFAULT_WIDTH_PC;
const DEFAULT_ASPECT_SP = DEFAULT_HEIGHT_SP / DEFAULT_WIDTH_SP;
const DPR = 2;

export class LoadingText {
  constructor() {
    this._els = {
      company: document.querySelector(".js-loading-text-company"),
      contact: document.querySelector(".js-loading-text-contact"),
      recruit: document.querySelector(".js-loading-text-recruit"),
      services: document.querySelector(".js-loading-text-services"),
      shop: document.querySelector(".js-loading-text-shop"),
    };
  }

  async init(page = null) {
    // 下層ページ用
    this._page = page;

    this._resize();
    window.addEventListener("resize", this._resize.bind(this));

    function uniq(array) {
      return array.filter((item, index, self) => self.indexOf(item) === index);
    }
    const alphabets = uniq(Config.LoadingTexts.join("").split(""));

    await preloadImages(alphabets.map(name => {
      return `${constants.imgPath}top/mainvisual-text-${name}.svg`;
    }));

    alphabets.forEach(letter => {
      const els = document.querySelectorAll(`.js-loading-letter-${letter}`);
      els.forEach(el => {
        el.style.backgroundImage = `url(${constants.imgPath}top/mainvisual-text-${letter}.svg)`;
      });
    });
  }

  async show(page) {
    this._page = page;
    this._resize();

    if (!this._els[page].classList.contains("is-fadein")) this._els[page].classList.add("is-fadein");
  }

  hide() {
    Object.keys(this._els).forEach(key => {
      this._els[key].classList.remove("is-fadein");
    });
  }

  _resize() {
    if (this._page == null) return;

    if (this._isSP) {
      const fontSize = 60;
      this._page.split("").forEach((letter, index) => {
        const el = this._els[this._page].querySelector(`.js-loading-letter-${index}`);
        if (el) el.style.marginLeft = `${Config.LoadingTextCompanyMargins[this._page][index] * fontSize / 160}px`;
      });
    } else {
      const fontSize = window.innerHeight / window.innerWidth >= DEFAULT_ASPECT_PC || window.innerWidth < 700
        ? this._clamp(180 * (window.innerWidth / DEFAULT_WIDTH_PC), 85, 160) // 縦長
        : this._clamp(160 * (window.innerHeight / DEFAULT_HEIGHT_PC), 120, 160); // 横長

      this._page.split("").forEach((letter, index) => {
        const el = this._els[this._page].querySelector(`.js-loading-letter-${index}`);
        if (el) el.style.width = `${158 * fontSize / 160}px`;
        if (el) el.style.height = `${120 * fontSize / 160}px`;
        if (el) el.style.marginLeft = `${Config.LoadingTextCompanyMargins[this._page][index] * fontSize / 160}px`;
      });
    }
  }

  _clamp(value, min, max) {
    let v = value;
    if (value < min) v = min;
    if (value > max) v = max;
    return v;
  }
}

export default new LoadingText;
