import * as easings from "../lib/easings";

const RATE = 0.8;

export const PHASE = [
  // phase 0: すべてが隠れている状態
  {
    // maskで使用する4頂点。Canvasサイズを基準として%で表現する(到達点)
    rectSP: [
      { x: 0, y: 100 }, // phase1時点での左下
      { x: 0, y: 100 }, // phase1時点での左上
      { x: 0, y: 100 }, // phase1時点での右上
      { x: 0, y: 100 }, // phase1時点での右下
    ],
    rectPC: [
      { x: 0, y: 100 }, // phase1時点での左下
      { x: 0, y: 100 }, // phase1時点での左上
      { x: 0, y: 100 }, // phase1時点での右上
      { x: 0, y: 100 }, // phase1時点での右下
    ],
    // このphaseに使用する時間
    moveDuration: 0,
    // このphaseへの到達後停止する時間
    pauseTime: 0,
    // 使用するeasings
    easing: easings.linear,
  },

  // phase 1: 左下から右上に向かって三角形の形で出現する
  {
    rectSP: [
      { x: 0, y: 130 },
      { x: 0, y: 55 },
      { x: 110, y: 3 }, // 実装の都合上、x座標は可能な限り100に近付ける
      { x: 110, y: 3 },
    ],
    rectPC: [
      { x: 0, y: 130 },
      { x: 0, y: 55 },
      { x: 110, y: -8 }, // 実装の都合上、x座標は可能な限り100に近付ける
      { x: 110, y: -8 },
    ],
    moveDuration: 1000 * RATE,
    pauseTime: 0,
    easing: easings.easeInExpo,
  },
  {
    rectSP: [
      { x: 0, y: 148 },
      { x: 0, y: 48 },
      { x: 110, y: 0 }, // 実装の都合上、x座標は可能な限り100に近付ける
      { x: 110, y: 6 },
    ],
    rectPC: [
      { x: 0, y: 148 },
      { x: 0, y: 48 },
      { x: 110, y: -8 }, // 実装の都合上、x座標は可能な限り100に近付ける
      { x: 110, y: -8 },
    ],
    moveDuration: 1000 * RATE,
    pauseTime: 0,
    easing: easings.easeLoadingCooldown,
  },

  // phase 2: 矩形が上下に移動する
  // 左辺は頂点の上下が入れ替わる
  {
    rectSP: [
      { x: 0, y: 28 },
      { x: 0, y: 40 },
      { x: 100, y: 60 },
      { x: 100, y: 72 },
    ],
    rectPC: [
      { x: 0, y: 23 },
      { x: 0, y: 34 },
      { x: 100, y: 66 },
      { x: 100, y: 78 },
    ],
    moveDuration: 500 * RATE,
    pauseTime: 0,
    easing: easings.easeInCubic,
  },

  {
    rectSP: [
      { x: 0, y: 26 },
      { x: 0, y: 39 },
      { x: 100, y: 61 },
      { x: 100, y: 74 },
    ],
    rectPC: [
      { x: 0, y: 21 },
      { x: 0, y: 33 },
      { x: 100, y: 67 },
      { x: 100, y: 80 },
    ],
    moveDuration: 500 * RATE,
    pauseTime: 0,
    easing: easings.easeOutSine,
  },

  // phase 4: 矩形が中央に収束し、文字列が完全に表示される
  {
    rectSP: [
      { x: 0, y: 50 },
      { x: 0, y: 50 },
      { x: 100, y: 50 },
      { x: 100, y: 50 },
    ],
    rectPC: [
      { x: 0, y: 50 },
      { x: 0, y: 50 },
      { x: 100, y: 50 },
      { x: 100, y: 50 },
    ],
    moveDuration: 2400 * RATE,
    pauseTime: 1000,
    easing: easings.easeLoadingFinish,
  },
];
