export const LoadingTexts = [
  "company",
  "services",
  "contact",
  "recruit",
  "shop",
];

// fontSize: 160pxを基準とした、文字間隔
export const LoadingTextCompanyMargins = {
  company:  [1, -33, -7, -20, -55, -30, -34],
  services: [-7, -52, -48, -51, -71, -67, -48, -60],
  contact:  [5, -32, -20, -42, -56, -36, -52],
  recruit:  [4, -53, -46, -41, -45, -67, -79],
  shop:     [-6, -41, -22, -29],
};
