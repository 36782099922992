import UA from "./lib/UA";
import { loading, loadingPage } from "./loading/index";
// import loadingOld from "./loadingOld/index";
import mainvisual from "./mainvisual/index";
// import mainvisualOld from "./mainvisualOld/index";

if (UA.isSP) {
  document.body.classList.add("is-sp");
} else if (UA.isPC) {
  document.body.classList.add("is-pc");
}

const rootEl = {
  loading: document.querySelector(".js-loading-wrapper"),
  mainvisual: document.querySelector(".js-mainvisual-wrapper"),
  // loadingOld: document.querySelector("#canvas-loading-old"),
  // mainvisualOld: document.querySelector("#canvas-mainvisual-old"),
  loadingPage: document.querySelector(".js-loading-firstview"),
};

window.KANO_CORP = {};

if (rootEl.loading) {
  loading();
}

if (rootEl.mainvisual) {
  mainvisual();
}
//
// if (rootEl.loadingOld) {
//   const page = rootEl.loadingOld.getAttribute("data-page");
//   loadingOld(rootEl.loadingOld, page);
// }
//
// if (rootEl.mainvisualOld) {
//   mainvisualOld(rootEl.mainvisualOld);
// }

if (rootEl.loadingPage) {
  const page = rootEl.loadingPage.getAttribute("data-page");
  loadingPage(page);
}
