// すべて小文字表記＆ファイル名を合わせておく
export const LoopImageNames = [
  "monja",
  "souzai",
  "sakana",
  "dashi",
];

export const LoopImageNameTextMargins = {
  monja: [0, -15, -32, -77, -70],
  souzai: [0, -62, -43, -59, -62, -97],
  sakana: [0, -68, -45, -60, -49, -46],
  dashi: [0, -61, -72, -58, -88],
};
