import MainvisualApp from "./App";
import * as Config from "./Config";
import { preloadImages } from "../lib/preloadImage";
import UA from "../lib/UA";
const constants = require("../../../constants.json");

async function startMainvisualAnimation(el) {
  const isSP = UA.isSP;
  // ページを開いてから一定秒後に演出を開始する
  await preloadImages(Config.LoopImageNames.map(name => {
    return `${constants.imgPath}top/mainvisual-${name}-${isSP ? "sp" : "pc"}.png`;
  }));
  const alphabets = uniq(Config.LoopImageNames.join("").split(""));
  await preloadImages(alphabets.map(name => {
    return `${constants.imgPath}top/mainvisual-text-${name}.svg`;
  }));
  MainvisualApp.init();
  MainvisualApp.startAnimation();
}

function uniq(array) {
  return array.filter((item, index, self) => self.indexOf(item) === index);
}

export default startMainvisualAnimation;
