import Cookies from "js-cookie";
import UA from "../lib/UA";
import wait from "../lib/wait";
import * as Config from "./Config";
const constants = require("../../../constants.json");

const DEFAULT_WIDTH_PC= 1280;
const DEFAULT_HEIGHT_PC = 720;
const DEFAULT_WIDTH_SP = 375;
const DEFAULT_HEIGHT_SP = 750;

const DEFAULT_ASPECT_PC = DEFAULT_HEIGHT_PC / DEFAULT_WIDTH_PC;
const DEFAULT_ASPECT_SP = DEFAULT_HEIGHT_SP / DEFAULT_WIDTH_SP;
const DPR = 2;

export class MainvisualApp {
  constructor() {
    this._els = {
      wrapper: document.querySelector(".js-mainvisual-wrapper"),
      inner: document.querySelector(".js-mainvisual-inner"),
      intro: document.querySelector(".js-mainvisual-intro"),
      flip: document.querySelector(".js-mainvisual-flip"),
      loop: document.querySelector(".js-mainvisual-loop"),
      loopItem: document.querySelectorAll(".js-mainvisual-loop-item"),
    };
    this._currentLoopNameIndex = 0;
  }

  init() {
    this._resize();
    window.addEventListener("resize", this._resize.bind(this));

    function uniq(array) {
      return array.filter((item, index, self) => self.indexOf(item) === index);
    }
    const alphabets = uniq(Config.LoopImageNames.join("").split(""));
    alphabets.forEach(letter => {
      const els = document.querySelectorAll(`.js-mainvisual-text-${letter}`);
      els.forEach((el, elIndex) => {
        el.style.backgroundImage = `url(${constants.imgPath}top/mainvisual-text-${letter}.svg)`;
      });
    });
  }

  async startAnimation() {
    await wait(1000);

    // 全体を表示しておく
    this._els.wrapper.classList.add("is-show");

    // intro
    // 24h以内のアクセスの場合、表示しない
    if (Cookies.get("skip-intro") !== "true") {
      Cookies.set("skip-intro", "true", { expires: 1 });
      // 実際はifブロック内に入れる処理
      this._els.intro.classList.add("is-playing");
      await wait(UA.isSP ? 2600 : 2300);
      this._els.flip.classList.add("is-playing");
      await wait(UA.isSP ? 300 : 700);
      this._els.intro.classList.remove("is-playing");

      // flip
      await wait(2500);
      window.setTimeout(() => {
        this._els.flip.classList.remove("is-playing");
      }, 500);
    }

    // loop
    this._els.loop.classList.add("is-playing");
    this._els.wrapper.style.maxWidth = `100vw`;

    // 最初のもんじゃだけ演出の仕方が異なる
    const loopMonjaEl = Array.from(this._els.loopItem).find(el => {
      return el.getAttribute("data-item") === "monja";
    });
    loopMonjaEl.classList.add("is-monja-init");
    await wait(2500);
    loopMonjaEl.classList.add(`is-leave`);
    window.setTimeout(() => {
      loopMonjaEl.classList.remove("is-monja-init");
    }, 500);
    window.setTimeout(() => {
      loopMonjaEl.classList.remove(`is-leave`);
    }, 2000);

    this._currentLoopNameIndex = 1;
    this._loopAnimation();
  }

  async _loopAnimation() {
    let name = Config.LoopImageNames[this._currentLoopNameIndex];
    if (name == null) {
      this._currentLoopNameIndex = 0;
      name = Config.LoopImageNames[this._currentLoopNameIndex];
    }
    const el = Array.from(this._els.loopItem).find(el => {
      return el.getAttribute("data-item") === name;
    });

    el.classList.add(`is-show`);
    this._els.loop.classList.remove("is-starting-loop");
    await wait(50);
    this._els.loop.classList.add("is-starting-loop");
    await wait(3000);
    el.classList.add(`is-leave`);

    window.setTimeout(() => {
      el.classList.remove(`is-show`);
    }, 500);

    window.setTimeout(() => {
      el.classList.remove(`is-leave`);
    }, 2000);

    this._currentLoopNameIndex += 1;
    this._loopAnimation();
  }

  _resize() {
    const isSP = UA.isSP;
    this._scale = 1;
    this._textScale = 1;
    const width = isSP ? DEFAULT_WIDTH_SP * DPR : DEFAULT_WIDTH_PC * DPR;
    const height = isSP ? width * DEFAULT_ASPECT_SP : width * DEFAULT_ASPECT_PC;

    this._els.wrapper.style.height = `${window.innerHeight}px`;

    // 全体をcontainする
    if (isSP) { // sp
      this._scale = window.innerWidth / DEFAULT_WIDTH_SP;
    } else if (window.innerHeight / window.innerWidth >= DEFAULT_ASPECT_PC) { // pc 縦長
      this._scale = 1;
      this._textScale = window.innerWidth / DEFAULT_WIDTH_PC * 1.22;
    } else { // pc 横長
      this._scale = (window.innerWidth / DEFAULT_WIDTH_PC) * ((window.innerHeight / window.innerWidth) / DEFAULT_ASPECT_PC);
    }
    this._els.inner.style.width = `${width / DPR}px`;
    this._els.inner.style.height = `${height / DPR}px`;

    this._els.inner.style.transform = `scale(${this._scale})`;

    // 全体のサイズに対する文字の拡大比率
    function uniq(array) {
      return array.filter((item, index, self) => self.indexOf(item) === index);
    }
    const alphabets = uniq(Config.LoopImageNames.join("").split(""));
    alphabets.forEach(letter => {
      const els = document.querySelectorAll(`.js-mainvisual-text-${letter}`);
      els.forEach((el, elIndex) => {
        el.style.width = `${Math.min(220 * this._textScale, 220)}px`;
        el.style.height = `${Math.min(165 * this._textScale, 165)}px`;
      });
    });
    document.querySelectorAll(`.js-mainvisual-text`).forEach((el, elIndex) => {
      Config.LoopImageNames.forEach((name) => {
        for (let i = 0; i < name.length; i += 1) {
          if (el.classList.contains(`js-mainvisual-text-${name}-${i}`)) {
            el.style.marginLeft = `${Config.LoopImageNameTextMargins[name][i] * 0.86 * Math.min(this._textScale, 1)}px`;
          }
        }
      });
    });
  }

  _clamp(value, min, max) {
    let v = value;
    if (value < min) v = min;
    if (value > max) v = max;
    return v;
  }
}

export default new MainvisualApp;
