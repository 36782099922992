import * as PIXI from "pixi.js";
import { PHASE } from "./Phase";
import * as Config from "./Config";
import * as easings from "../lib/easings";
import UA from "../lib/UA";
import wait from "../lib/wait";
import LoadingText from "./Texts";

const constants = require("../../../constants.json");

/**
 *
 * ページ間遷移の演出
 * COMPANY/CONTACT/RECRUIT/SERVICE/SHOP ページへ遷移するときに表示されるアニメーション
 * 背景画像をマスクする矩形が回転しながら、表題を裏側に表示する
 *
 * 表示する文字列および背景画像のファイル名は、canvas#canvas-loadingに当たっているdata-page属性となる
 *
**/

const DEFAULT_WIDTH_PC = 1280;
const DEFAULT_HEIGHT_PC = 720;
const DEFAULT_WIDTH_SP = 375;
const DEFAULT_HEIGHT_SP = 750;

const DEFAULT_ASPECT_PC = DEFAULT_HEIGHT_PC / DEFAULT_WIDTH_PC;
const DEFAULT_ASPECT_SP = DEFAULT_HEIGHT_SP / DEFAULT_WIDTH_SP;
const DPR = 2;

export class LoadingApp {
  constructor() {
    this._timeFromInit = 0;
    this._startTime = null;
    this._elapsedTime = 0;
    this._isSP = UA.isSP;
  }

  /**
   * ================================================================================
   *
   * public method
   * Canvasの初期化、画像の読み込みなどを行う
   *
   * ================================================================================
  **/
  async init() {
    this._page = "company";

    this._els = {
      canvas: document.querySelector("#canvas-loading"),
      wrapper: document.querySelector(".js-loading-wrapper"),
    };

    this._initRenderer(this._els.canvas);
    await LoadingText.init();

    // 画像の読み込み
    await this._loadImages(constants.imgPath, [
      { name: `loading-company`, ext: `${this._isSP ? "-sp" : "-pc"}.jpg` },
      { name: `loading-services`, ext: `${this._isSP ? "-sp" : "-pc"}.jpg` },
      { name: `loading-contact`, ext: `${this._isSP ? "-sp" : "-pc"}.jpg` },
      { name: `loading-recruit`, ext: `${this._isSP ? "-sp" : "-pc"}.jpg` },
      { name: `loading-shop`, ext: `${this._isSP ? "-sp" : "-pc"}.jpg` },
    ]);
  }

  /**
   * ================================================================================
   *
   * 画像をPIXI.loaderを通して読み込む
   *
   * ================================================================================
  **/
  _loadImages(basePath, assets) {
    if (this._renderer == null) return;

    assets.forEach(asset => {
      this._loader.add(asset.name, `${basePath}loading/${asset.name}${asset.ext}`);
    });
    return new Promise((resolve) => {
      this._loader.load(() => resolve());
    });
  }

  /**
   * ================================================================================
   *
   * DOMの適用およびPIXI.Applicationの作成
   *
   * ================================================================================
  **/
  _initRenderer(el) {
    this._width = this._isSP ? DEFAULT_WIDTH_SP * DPR : DEFAULT_WIDTH_PC * DPR;
    this._height = this._isSP ? this._width * DEFAULT_ASPECT_SP : this._width * DEFAULT_ASPECT_PC;

    this._app = new PIXI.Application({
      view: el,
      width: this._width,
      height: this._height,
      transparent: true,
      antialias: true,
      resolution: 1,
    });

    this._renderer = this._app.renderer;
    this._loader = this._app.loader;
    this._stage = this._app.stage;
    this._ticker = this._app.ticker;

    this._ticker.add(() => {
      this._update();
      this._render();
      this._renderer && this._renderer.render(this._stage);

      // 仮対応: 演出完了後にtickを止める
      if (this._currentPhase.phase === PHASE.length - 1 && this._currentPhase.moveProgress === 1 && this._currentPhase.pauseProgress === 1) {
        this._ticker.stop();
      }
    });

    this._ticker.stop();

    this._resize();
    window.addEventListener("resize", this._resize.bind(this));
  }

  /**
   * ================================================================================
   *
   * 演出の終了
   *
   * ================================================================================
  **/
  finish() {
    this._ticker.stop();
    LoadingText.hide();
    this._els.wrapper.classList.remove("is-playing");
  }

  /**
   * ================================================================================
   *
   * resize時にcanvasサイズを調整
   *
   * ================================================================================
  **/
  _resize() {
    this._width = this._isSP ? DEFAULT_WIDTH_SP * DPR : DEFAULT_WIDTH_PC * DPR;
    this._height = this._isSP ? this._width * DEFAULT_ASPECT_SP : this._width * DEFAULT_ASPECT_PC;

    const rootEl = document.querySelector(".js-loading-wrapper");
    if (rootEl) rootEl.style.height = `${window.innerHeight}px`;

    // canvasがcoverするように調整
    if (this._isSP) {
      this._renderer.view.style.width = `${this._width / DPR * (window.innerWidth / DEFAULT_WIDTH_SP)}px`;
      this._renderer.view.style.height = `${this._height / DPR * (window.innerWidth / DEFAULT_WIDTH_SP)}px`;
    } else {
      if (window.innerHeight / window.innerWidth >= DEFAULT_ASPECT_PC) {
        // 縦長
        this._renderer.view.style.width = `${this._width / DPR * (window.innerHeight / DEFAULT_HEIGHT_PC)}px`;
        this._renderer.view.style.height = `${this._height / DPR * (window.innerHeight / DEFAULT_HEIGHT_PC)}px`;
      } else {
        // 横長
        this._renderer.view.style.width = `${this._width / DPR * (window.innerWidth / DEFAULT_WIDTH_PC)}px`;
        this._renderer.view.style.height = `${this._height / DPR * (window.innerWidth / DEFAULT_WIDTH_PC)}px`;
      }
    }
  }



  /**
   * ================================================================================
   *
   * public method
   * このメソッドが呼ばれると、アニメーションを開始する
   *
   * ================================================================================
  **/
  startAnimation(page) {
    LoadingText.show(page);
    this._els.wrapper.classList.add("is-playing");

    this._page = page;
    this._initStage();

    this._timeFromInit = 0;
    this._startTime = 0;
    this._elapsedTime = 0;

    this._tick();
  }

  /**
   * ================================================================================
   *
   * 画面の初期状態をつくる（背景画像＋テキスト＋マスクレイヤー）
   *
   * ================================================================================
  **/
  _initStage() {
    // background
    this._background = new PIXI.Sprite(this._loader.resources[`loading-${this._page}`].texture);
    this._background.width = this._width;
    this._background.height = this._height;

    // mask for background
    this._maskContainer = new PIXI.Container();
    this._maskRect = new PIXI.Graphics();
    this._maskContainer.addChild(this._maskRect);
    this._background.mask = this._maskContainer;

    this._stage.addChild(this._background);
  }

  /**
   * ================================================================================
   *
   * ticker
   *
   * ================================================================================
  **/
  _tick() {
    this._ticker.start();
  }

  /**
   * ================================================================================
   *
   * timer
   * startAnimation()が呼ばれると、それまでの経過時間が this._startTime に格納される
   * 以降の経過時間の処理は this._elapsedTime にて行う。単位はms。
   *
   * ================================================================================
  **/
  _update() {
    this._timeFromInit += this._ticker.elapsedMS;
    if (this._startTime == null) return;
    this._elapsedTime = this._timeFromInit - this._startTime + 500;
  }

  /**
   * ================================================================================
   *
   * render
   *
   * ================================================================================
  **/
  _render() {
    this._maskContainer = new PIXI.Container();

    // このコードは、左2辺と右2辺が平行である前提で記述
    // 途中で図形が交差する箇所があり、PIXI.jsの性質上、四角形を2箇所つくるようにしている

    // 左側/右側2点のうち、上側/下側にある点
    const pointLeftTopY = Math.min(this._getCurrentPointPosition(0, "y"), this._getCurrentPointPosition(1, "y"));
    const pointLeftBottomY = Math.max(this._getCurrentPointPosition(0, "y"), this._getCurrentPointPosition(1, "y"));
    const pointRightTopY = Math.min(this._getCurrentPointPosition(2, "y"), this._getCurrentPointPosition(3, "y"));
    const pointRightBottomY = Math.max(this._getCurrentPointPosition(2, "y"), this._getCurrentPointPosition(3, "y"));

    // 左右の辺の長さ(y座標の差)に応じて、中間点（交差想定位置）を左からどれくらいの割合の位置にするか決める
    // 中間点の処理を厳密に計算していないため（x座標が大きく変化しない想定で相似で計算）、各点のx座標を大きくいじらないこと
    const centerPointPercent = (pointLeftBottomY - pointLeftTopY) / ((pointLeftBottomY - pointLeftTopY) + (pointRightBottomY - pointRightTopY));

    // 中間点(Top, Bottomは初期状態での位置のため、交差する場合は入れ替わる)
    const centerPointTop = this._getCurrentPointPosition(1, "y") + (this._getCurrentPointPosition(2, "y") - this._getCurrentPointPosition(1, "y")) * centerPointPercent;
    const centerPointBottom = this._getCurrentPointPosition(0, "y") + (this._getCurrentPointPosition(3, "y") - this._getCurrentPointPosition(0, "y")) * centerPointPercent;

    // 中間点(Left, Rightは基本的に同じx座標になる想定で実装。ただし多少の変化の場合は違和感がないので対応できるようにする（要検証）。2点のx座標によっては入れ替わる可能性あり)
    const centerPointLeft = this._getCurrentPointPosition(1, "x") + (this._getCurrentPointPosition(2, "x") - this._getCurrentPointPosition(1, "x")) * centerPointPercent;
    const centerPointRight = this._getCurrentPointPosition(0, "x") + (this._getCurrentPointPosition(3, "x") - this._getCurrentPointPosition(0, "x")) * centerPointPercent;

    this._maskRect = new PIXI.Graphics()
      .lineStyle(0)
      .beginFill(0x000000)
      // 左側矩形
      .moveTo( // 左下頂点
        this._getCurrentPointPosition(0, "x"),
        pointLeftBottomY,
      )
      .lineTo( // 左上頂点
        this._getCurrentPointPosition(1, "x"),
        pointLeftTopY,
      )
      .lineTo( // 上側中間点
        Math.min(centerPointLeft, centerPointRight),
        Math.min(centerPointTop, centerPointBottom),
      )
      .lineTo( // 下側中間点
        Math.max(centerPointLeft, centerPointRight),
        Math.max(centerPointTop, centerPointBottom),
      )

      // 右側矩形
      .moveTo( // 下側中間点
        Math.max(centerPointLeft, centerPointRight),
        Math.max(centerPointTop, centerPointBottom),
      )
      .lineTo( // 上側中間点
        Math.min(centerPointLeft, centerPointRight),
        Math.min(centerPointTop, centerPointBottom),
      )
      .lineTo( // 右上頂点
        this._getCurrentPointPosition(2, "x"),
        pointRightTopY,
      )
      .lineTo( // 右下頂点
        this._getCurrentPointPosition(3, "x"),
        pointRightBottomY,
      )
      .endFill();

    this._maskContainer.addChild(this._maskRect);
    this._background.mask = this._maskContainer;
  }

  /**
   * ================================================================================
   *
   * 矩形の頂点座標を返す
   * @params index: number, direction: "x" | "y"
   * @return number
   *
   * ================================================================================
  **/
  _getCurrentPointPosition(index, direction) {
    const prevPhasePositions = this._currentPhase.phase > 0 ? (this._isSP ? PHASE[this._currentPhase.phase - 1].rectSP : PHASE[this._currentPhase.phase - 1].rectPC) : null;
    const currentPhasePositions = (this._isSP ? PHASE[this._currentPhase.phase].rectSP : PHASE[this._currentPhase.phase].rectPC);

    switch (direction) {
      case "x": {
        return prevPhasePositions ?
          this._width * (prevPhasePositions[index].x + (currentPhasePositions[index].x - prevPhasePositions[index].x) * PHASE[this._currentPhase.phase].easing(this._currentPhase.moveProgress)) / 100 :
          this._width * currentPhasePositions[index].x / 100
      }
      case "y": {
        return prevPhasePositions ?
          this._height * (prevPhasePositions[index].y + (currentPhasePositions[index].y - prevPhasePositions[index].y) * PHASE[this._currentPhase.phase].easing(this._currentPhase.moveProgress)) / 100 :
          this._height * currentPhasePositions[index].y / 100
      }
      default: {
        return 0;
      }
    }
  }

  /**
   * ================================================================================
   *
   * 現在のフェーズ、およびその進行度を返す
   * @return object
   *   phase(現在のphase index): number
   *   moveProgress(rectの移動進行度 0~1): number
   *   pauseProgress(rect移動後の停止進行度 0~1): number
   *
   * ================================================================================
  **/
  get _currentPhase() {
    let totalTime = 0;
    let moveProgress = 0;
    let pauseProgress = 0;
    const index = PHASE.findIndex((p, i) => {
      const moveStartTime = totalTime; // 動き出し時間
      const pauseStartTime = moveStartTime + p.moveDuration; // 停止時間
      totalTime += (p.moveDuration + p.pauseTime);

      if (this._elapsedTime < pauseStartTime) {
        // 進行中
        moveProgress = p.moveDuration === 0 ? 1 : 1 - (pauseStartTime - this._elapsedTime) / p.moveDuration;
        return true;
      } else if (this._elapsedTime < totalTime) {
        // 停止中
        moveProgress = 1;
        pauseProgress = p.pauseTime === 0 ? 1 : 1 - (totalTime - this._elapsedTime) / p.pauseTime;
        return true;
      } else {
        // そのphaseの終了時間を超えている
        return false;
      }
    });

    if (this._elapsedTime === 0) return {
      phase: 0,
      moveProgress: 0,
      pauseProgress: 0,
    }
    if (index === -1) return {
      phase: PHASE.length - 1,
      moveProgress: 1,
      pauseProgress: 1,
    };
    return {
      phase: index,
      moveProgress,
      pauseProgress,
    };
  }

  /**
   * ================================================================================
   *
   * 値の丸め込み
   * @params value: number, min: number, max: number
   * @return number
   *
   * ================================================================================
  **/
  _clamp(value, min, max) {
    let v = value;
    if (value < min) v = min;
    if (value > max) v = max;
    return v;
  }
}

export default new LoadingApp;
