/**
 * @return Promise
 */

export default function wait(waitTime) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, waitTime);
  });
}
