/**
 * @return {Object} 判定結果を全て格納したオブジェクト
 */

const getUA = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  const version = window.navigator.appVersion.toLowerCase();

  const data = {
    Tablet:
      (ua.indexOf("windows") !== -1 && ua.indexOf("touch") !== -1 && ua.indexOf("tablet pc") === -1) ||
      ua.indexOf("ipad") !== -1 ||
      (ua.indexOf("android") !== -1 && ua.indexOf("mobile") === -1) ||
      (ua.indexOf("firefox") !== -1 && ua.indexOf("tablet") !== -1) ||
      ua.indexOf("kindle") !== -1 ||
      ua.indexOf("silk") !== -1 ||
      ua.indexOf("playbook") !== -1,
    Mobile:
      (ua.indexOf("windows") !== -1 && ua.indexOf("phone") !== -1) ||
      ua.indexOf("iphone") !== -1 ||
      ua.indexOf("ipod") !== -1 ||
      (ua.indexOf("android") !== -1 && ua.indexOf("mobile") !== -1) ||
      (ua.indexOf("firefox") !== -1 && ua.indexOf("mobile") !== -1) ||
      ua.indexOf("blackberry") !== -1,
    iOS: ua.indexOf("iphone") !== -1 || ua.indexOf("ipod") !== -1 || ua.indexOf("ipad") !== -1,
    iPad: ua.indexOf("ipad") !== -1,
    iPhone: ua.indexOf("iphone") !== -1,
    Android: ua.indexOf("android") !== -1,
    IE11: ua.indexOf("trident/7") !== -1,
    Edge: ua.indexOf("edge") !== -1,
    Chrome: ua.indexOf("chrome") !== -1 && ua.indexOf("edge") === -1,
    Firefox: ua.indexOf("firefox") !== -1,
    Safari: ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1,
    Opera: ua.indexOf("opera") !== -1
  }
  data.isSP = data.Mobile || data.Tablet;
  data.isPC = !data.isSP;

  return data;
};

export default getUA();
