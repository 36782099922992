import LoadingApp from "./App";
import LoadingText from "./Texts";
import wait from "../lib/wait";

export async function loading() {
  // 諸々の読み込み（ページを開いた時点で行なっておく）
  await LoadingApp.init();

  // アニメーションを開始するときに以下を実行
  window.KANO_CORP.start = (page) => LoadingApp.startAnimation(page);
  // アニメーションを終了するとき（ページ遷移が完了したとき）に以下を実行
  window.KANO_CORP.finish = () => LoadingApp.finish();

  // 以下は挙動サンプル
  const els = {
    company: document.querySelectorAll(".js-loading-link-company"),
    services: document.querySelectorAll(".js-loading-link-services"),
    recruit: document.querySelectorAll(".js-loading-link-recruit"),
    shop: document.querySelectorAll(".js-loading-link-shop"),
    contact: document.querySelectorAll(".js-loading-link-contact"),
  };

  Object.keys(els).forEach(key => {
    els[key].forEach(el => {
      el.addEventListener("click", async () => {
        // ページを指定してアニメーション開始（遷移が発生する時に行う）
        await window.KANO_CORP.start(key);
        await wait(7000);
        // 任意のタイミングで終了する（表示を終える）
        await window.KANO_CORP.finish();
        window.location.href = `./loading-${key}.html`;
      });
    });
  });
}

export async function loadingPage(page) {
  await LoadingText.init();
  LoadingText.show(page);
}
